import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { TextField } from '../../form/TextField';
import { SelectField } from '../../form/SelectField';
import { nextFiveWeekdaysArray, formatDatesForSelect } from '../../../utils';
import Modal from '../../shared/Modal';

export default function FlexdelForm(props) {
	const { recipient_flexdel_address, editParcel } = props;

	const [modalOpen, setModalOpen] = useState(false);

	const handleModalClose = () => setModalOpen(false);

	const dateOptions = formatDatesForSelect(nextFiveWeekdaysArray);
	const nextDayInMillis = dateOptions[0].value;

	const initialValues = {
		recipient_flexdel_date: nextDayInMillis,
		recipient_flexdel_address,
	};
	const handleSubmit = (values, { setSubmitting }) => {
		let currentFlexDelValue = values.recipient_flexdel_date;

		// possible to hold date value in millis as string ie.: "1605481200000"
		if (typeof currentFlexDelValue === 'string') {
			currentFlexDelValue = Number(currentFlexDelValue);
		}

		const newDate = new Date(currentFlexDelValue);
		editParcel(
			{
				recipient_flexdel_address: values.recipient_flexdel_address,
				recipient_flexdel_date: newDate,
				messageType: 'flexdel',
			},
			setSubmitting
		);
	};

	return (
		<div className="row">
			<div className="col-12 col-md-10 offset-md-1 pt-5 mt-5">
				<h4 className="text-center pb-3">
					Módosítanád a kézbesítés napját vagy a kézbesítési címet?
				</h4>
				<span>
					Amennyiben címet módosítasz, a csomag legkorábban holnap kerül
					kiszállításra.
				</span>
				<Formik initialValues={initialValues} onSubmit={handleSubmit}>
					{({ isSubmitting, submitForm }) => (
						<Form>
							<Field
								name="recipient_flexdel_date"
								label="Melyik nap szeretnéd megkapni a csomagot?"
								component={SelectField}
								options={dateOptions}
							/>
							<Field
								name="recipient_flexdel_address"
								label="Módosítanád a kézbesítés címét? Írd ide az új címet!"
								component={TextField}
							/>

							<button
								type="button"
								onClick={() => setModalOpen(true)}
								className="btn btn-light my-3"
								disabled={isSubmitting}
							>
								Módosítás
							</button>

							<Modal open={modalOpen}>
								<Modal.Title>
									<h5 className="modal-title">
										Biztosan módosítani szeretnéd a kiszállítást?
									</h5>
									<button
										type="button"
										className="close"
										data-dismiss="modal"
										aria-label="Close"
										onClick={handleModalClose}
									>
										<span aria-hidden="true">&times;</span>
									</button>
								</Modal.Title>
								<Modal.Content>
									Módosítási igényedet akkor tudjuk végrehajtani, ha még nem
									kísérelte meg a futár a csomagod kiszállítását. Kérdés esetén
									keresd ügyfélszolgálatunkat!
								</Modal.Content>
								<Modal.Actions>
									<button
										type="button"
										className="btn btn-secondary"
										data-dismiss="modal"
										onClick={handleModalClose}
									>
										Mégsem
									</button>
									<button
										type="button"
										className="btn btn-primary"
										onClick={submitForm}
									>
										Módosítom a szállítást
									</button>
								</Modal.Actions>
							</Modal>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
}

FlexdelForm.defaultProps = {
	recipient_flexdel_address: undefined,
};

FlexdelForm.propTypes = {
	recipient_flexdel_address: PropTypes.string,
	editParcel: PropTypes.func.isRequired,
};
