import React from 'react';

export const SelectField = ({ label, field, form, lg, sm, options }) => {
	const { name, onBlur, onChange, value } = field;
	const { touched, errors } = form;
	const hasError = touched[name] && errors[name];
	const largeClass = lg ? 'custom-select-lg' : '';
	const smallClass = sm ? 'custom-select-sm' : '';

	return (
		<div className="form-group">
			<label className={hasError ? 'text-danger' : ''} htmlFor={name}>
				{label}
			</label>
			<select
				className={`custom-select ${largeClass} ${smallClass}`}
				onBlur={onBlur}
				onChange={onChange}
				id={name}
				value={value}
			>
				{options.map((option) => (
					<option key={option.value} value={option.value}>
						{option.text}
					</option>
				))}
			</select>
			{hasError && <div className="invalid-feedback">{errors[name]}</div>}
		</div>
	);
};

SelectField.defaultProps = {
	lg: false,
	sm: false,
};
