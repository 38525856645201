import React from 'react';
import PropTypes from 'prop-types';

const ParcelDetailsHeader = ({ isDelivered, isRateable, isDpdParcel }) => {
	const renderHeader = () => {
		if (isRateable) {
			return <></>;
		}
		if (isDelivered) {
			return <SuccessfulDelivery />;
		}
		return (
			<>
				<h2>Csomagoddal kapcsolatos részletek</h2>
				{!isDpdParcel ? <HelpCourier /> : <DeliveryByDPD />}
			</>
		);
	};

	return (
		<section className="bg-primary text-center pb-0 pt-5 pt-md-2 pb-5">
			<div className="container">
				<div className="row">
					<div className="col-12 col-md-8 offset-md-2 text-md-center pb-5">
						{renderHeader()}
					</div>
				</div>
			</div>
		</section>
	);
};

const SuccessfulDelivery = () => {
	return <h2>Sikeres csomagkézbesítés</h2>;
};

const HelpCourier = () => {
	return (
		<h4 className="mb-5 mb-md-4 font-weight-normal pb-lg-5 pt-3">
			<span className="font-weight-bold">
				Segítsd futárunk sikeres kézbesítését
			</span>{' '}
			azzal, hogy átnézed és ha szükséges módosítod vagy kiegészíted a
			rendelkezésünkre álló adataidat.
		</h4>
	);
};

const DeliveryByDPD = () => {
	return (
		<div style={{ textAlign: 'center' }}>
			<img
				src="/dpd.png"
				alt="DPD"
				style={{ marginTop: '20px', width: '100px' }}
			/>
			<h4 className="mb-5 mb-md-4 font-weight-normal pb-lg-5 pt-3">
				Csomagodat a DPD Hungary Kft. megbízásából a ViddL szállítja házhoz.
			</h4>
		</div>
	);
};

ParcelDetailsHeader.propTypes = {
	isDelivered: PropTypes.bool.isRequired,
};

export default ParcelDetailsHeader;
