import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { hungarianDayFormat, formatDateToTimeSlot } from '../../../utils';

const DeliveryDetails = ({
	canPayWithCreditCard,
	drop_off_stop,
	drop_off_address,
	cashOnDelivery,
	sender_company,
	recipient_name,
	recipient_company,
	recipient_phone,
	deliveryType,
}) => {
	const deliveryDate = drop_off_stop?.selected_time_slot
		? moment(drop_off_stop?.selected_time_slot)
		: null;
	const { title } = drop_off_address;

	return (
		<div className="row py-sm-5">
			<div className="col-12 col-md-10 offset-md-1">
				<h4 className="text-center mb-3">Amit tudunk a szállításodról...</h4>
			</div>
			<div className="col-12 col-md-5 offset-md-1">
				{deliveryDate && (
					<>
						<p className="font-weight-bold mb-2 mt-4 text-uppercase">
							Kézbesítés napja
						</p>
						<h5 className="text-secondary">
							<span className="font-weight-normal">
								{deliveryDate.format('YYYY.MM.DD')}
							</span>{' '}
							{hungarianDayFormat(deliveryDate.format('dddd'))}
						</h5>
						<p className="font-weight-bold mb-2 mt-4 text-uppercase">
							Kézbesítés időszaka
						</p>
						<h5 className="text-secondary">
							{formatDateToTimeSlot(
								drop_off_stop.selected_time_slot,
								deliveryType
							)}
						</h5>
					</>
				)}
				<p className="font-weight-bold mb-2 mt-4 text-uppercase">Feladó</p>
				<h5 className="text-secondary font-weight-normal">{sender_company}</h5>
				<p className="font-weight-bold mb-2 mt-4 text-uppercase">
					Utánvét összege
				</p>
				<h5 className="text-secondary font-weight-normal">
					{cashOnDelivery
						? `${cashOnDelivery.toLocaleString('hu-HU')} Ft`
						: 'Nincs utánvét'}
				</h5>
			</div>
			<div className="col-12 col-md-5 offset-md-1">
				<p className="font-weight-bold mb-2 mt-4 text-uppercase">Címzett név</p>
				<h5 className="text-secondary font-weight-normal">{recipient_name}</h5>
				<p className="font-weight-bold mb-2 mt-4 text-uppercase">Címzett cég</p>
				<h5 className="text-secondary font-weight-normal">
					{recipient_company}
				</h5>
				<p className="font-weight-bold mb-2 mt-4 text-uppercase">
					Kézbesítési cím
				</p>
				<h5 className="text-secondary font-weight-normal">{title}</h5>
				<p className="font-weight-bold mb-2 mt-4 text-uppercase">Telefonszám</p>
				<h5 className="text-secondary font-weight-normal">{recipient_phone}</h5>
			</div>

			<div className="col-12 col-md-10 offset-md-1 pt-4">
				<p className="font-weight-bold mb-2 mt-4 text-uppercase">Információ</p>
				<h5 className="font-weight-normal text-secondary">
					{canPayWithCreditCard
						? 'Az utánvét összegét készpénzben vagy kártyával tudod futárunknak kifizetni. Készpénzes fizetés esetén kérjük a pontos összeget készítsd elő érkezéséig.'
						: 'FIGYELEM! Az utánvét összegét csak készpénzben tudod futárunknak kifizetni. Kérjük a pontos összeget készítsd elő érkezéséig.'}
				</h5>
			</div>
		</div>
	);
};

DeliveryDetails.defaultProps = {
	recipient_name: '-',
	recipient_company: '-',
	recipient_phone: '-',
};

DeliveryDetails.propTypes = {
	recipient_name: PropTypes.string,
	recipient_company: PropTypes.string,
	recipient_phone: PropTypes.string,
	sender_company: PropTypes.string.isRequired,
	cashOnDelivery: PropTypes.number.isRequired,
	drop_off_stop: PropTypes.shape({
		selected_time_slot: PropTypes.string, // can be persed to date
	}).isRequired,
	drop_off_address: PropTypes.shape({
		title: PropTypes.string,
	}).isRequired,
};

export default DeliveryDetails;
