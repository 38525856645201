import React from 'react';

const Star = (props) => (
	<svg viewBox="0 0 32 32" {...props}>
		<path
			fillRule="nonzero"
			clipRule="nonzero"
			d="M19.7447 11.8328L26.198 12.3928C27.3714 12.4861 27.838 13.9528 26.9447 14.7261L22.0514 18.9661L23.518 25.2728C23.7847 26.4195 22.5447 27.3261 21.5314 26.7128L15.998 23.3661L10.4647 26.6995C9.45135 27.3128 8.21135 26.4061 8.47802 25.2595L9.94469 18.9661L5.05135 14.7261C4.15802 13.9528 4.63802 12.4861 5.81135 12.3928L12.2514 11.8461L14.7714 5.89948C15.2247 4.81948 16.7714 4.81948 17.2247 5.89948L19.7447 11.8328Z"
			fill={props.checked ? '#662FB7' : 'white'}
		/>
		<path
			fillRule="nonzero"
			clipRule="nonzero"
			d="M19.7447 11.8328L26.198 12.3928C27.3714 12.4861 27.838 13.9528 26.9447 14.7261L22.0514 18.9661L23.518 25.2728C23.7847 26.4195 22.5447 27.3261 21.5314 26.7128L15.998 23.3661L10.4647 26.6995C9.45135 27.3128 8.21135 26.4061 8.47802 25.2595L9.94469 18.9661L5.05135 14.7261C4.15802 13.9528 4.63802 12.4861 5.81135 12.3928L12.2514 11.8461L14.7714 5.89948C15.2247 4.81948 16.7714 4.81948 17.2247 5.89948L19.7447 11.8328ZM10.9847 23.8995L15.998 20.8728L21.0247 23.9128L19.6914 18.2061L24.118 14.3661L18.278 13.8595L15.998 8.4728L13.7314 13.8461L7.89137 14.3528L12.318 18.1928L10.9847 23.8995Z"
			fill="#662FB7"
		/>
	</svg>
);

export default Star;
