import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { hungarianDayFormat } from '../../../utils';

export default function FlexdelModified(props) {
	const { recipient_flexdel_date, recipient_flexdel_address } = props;

	const flexdel_date = moment(recipient_flexdel_date);

	const formattedDate = `${flexdel_date.format(
		'YYYY.MM.DD'
	)} ${hungarianDayFormat(flexdel_date.format('dddd'))}`;

	return (
		<div className="row">
			<div className="col-12 col-md-10 offset-md-1 pt-5 mt-5">
				<h4 className="text-center pb-3">Módosított adataid:</h4>
				{
					<div className="form-group">
						<label>Új kézbesítés napja:</label>
						<p className="form-control">{formattedDate}</p>
					</div>
				}
				{recipient_flexdel_address && (
					<div className="form-group">
						<label>Új kézbesítés címe:</label>
						<p className="form-control">{recipient_flexdel_address}</p>
					</div>
				)}
			</div>
		</div>
	);
}

FlexdelModified.defaultProps = {
	recipient_flexdel_address: undefined,
};

FlexdelModified.propTypes = {
	recipient_flexdel_address: PropTypes.string,
	recipient_flexdel_date: PropTypes.string.isRequired,
};
