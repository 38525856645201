import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Modal from '../../shared/Modal';

const PickupPoint = ({ sendPickupPointMessage }) => {
	const [modalOpen, setModalOpen] = useState(false);

	const handleModalClose = () => setModalOpen(false);

	return (
		<div className="row">
			<div className="col-12 col-md-10 offset-md-1 pt-5 mt-5 text-center">
				<h4 className="pb-3">Nehezen tudsz a kiszállítási időhöz igazodni?</h4>
				<h5 className="font-weight-normal text-secondary">
					Belvárosi irodánkban{' '}
					<span className="font-weight-bold">
						1054 Budapest, Széchenyi utca 1.
					</span>
					-es szám alatt{' '}
					<span className="font-weight-bold">Hétfő - Péntek, 10:00-18:00</span>{' '}
					között kollégáinktól átveheted!
				</h5>
				<button
					type="button"
					className="btn btn-light my-3"
					onClick={() => setModalOpen(true)}
				>
					Személyesen veszem át
				</button>
			</div>
			<Modal open={modalOpen}>
				<Modal.Title>
					<h5 className="modal-title">
						Biztosan csomagpontra kéred a csomagodat?
					</h5>
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={handleModalClose}
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</Modal.Title>
				<Modal.Content>
					Csomagodat <strong>5 munkanapig</strong> átveheted ügyfélszolgálati
					időben (Hétfő-Péntek 09:00-17:00) a ViddL irodában, címünk 1054
					Budapest, Széchenyi utca 1. Ha a mai napon szeretnél bejönni érte,
					akkor egyeztess ügyfélszolgálatunkkal a +36 70 758 2536 számon!
				</Modal.Content>
				<Modal.Actions>
					<button
						type="button"
						className="btn btn-secondary"
						data-dismiss="modal"
						onClick={handleModalClose}
					>
						Mégsem
					</button>
					<button
						type="button"
						className="btn btn-primary"
						onClick={sendPickupPointMessage}
					>
						Csomagpontra kérem a csomagot
					</button>
				</Modal.Actions>
			</Modal>
		</div>
	);
};

PickupPoint.propTypes = {
	sendPickupPointMessage: PropTypes.func.isRequired,
};

export default PickupPoint;
