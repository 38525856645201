import moment from 'moment';
import React from 'react';
import './CourierContact.scss';

const CourierContact = ({ courierPhoneNumber, deliveryDate }) => {
	const phoneNumberShowDuration = Number(
		process.env.GATSBY_COURIER_PHONE_NUMBER_SHOW_DURATION_IN_MINUTES
	);
	const minutesPassed = moment().diff(deliveryDate, 'minutes');
	const inTimeDuration = phoneNumberShowDuration >= minutesPassed;

	return (
		<div className="row card" id="courier-contact">
			{inTimeDuration ? (
				<div className="col-12" id="courier-contact-content">
					<h5>Hívd a futárt, még a környéken lehet!</h5>
					<p className="body1">
						Beszéld meg vele, hogy át tudja-e adni a csomagodat!
					</p>
					<div className="contact-holder">
						<p>futár telefonszáma</p>
						<p>{courierPhoneNumber}</p>
					</div>
				</div>
			) : (
				<div className="col-12" id="no-courier-near">
					<h6>Nem tudtuk átadni a csomagodat.</h6>
					<p className="body1">
						Sajnos a futár már nincs a környéken. Hamarosan e-mail-ben
						értesítünk a továbbiakról.
					</p>
				</div>
			)}
		</div>
	);
};

export default CourierContact;
