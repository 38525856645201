import React from 'react';

const Modal = ({ open, children }) => {
	return (
		<div
			className="modal fade"
			tabIndex="-1"
			role="dialog"
			style={{
				backgroundColor: 'rgba(0, 0, 0, 0.3)',
				...(open
					? { display: 'block', opacity: 100 }
					: { display: 'none', opacity: 0 }),
			}}
		>
			<div className="modal-dialog modal-dialog-centered" role="document">
				<div className="modal-content">
					{React.Children.map(children || null, (child, i) => (
						// eslint-disable-next-line react/no-array-index-key
						<child.type {...child.props} key={i} />
					))}
				</div>
			</div>
		</div>
	);
};

Modal.Title = ({ children }) => <div className="modal-header">{children}</div>;
Modal.Content = ({ children }) => <div className="modal-body">{children}</div>;
Modal.Actions = ({ children }) => (
	<div className="modal-footer">{children}</div>
);

export default Modal;
