import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { wrapComponent } from 'react-snackbar-alert';
import SignatureCanvas from 'react-signature-canvas';
import './Signature.scss';

class Signature extends React.Component {
	// eslint-disable-next-line react/state-in-constructor
	state = {
		deliveryCode: null,
	};

	sigPad = {};

	saveRecipientSignature = () => {
		const { _id, token, createSnackbar } = this.props;
		const trimmedDataURL = this.sigPad
			.getTrimmedCanvas()
			.toDataURL('image/png');

		axios
			.post(
				`/.netlify/functions/signature?${qs.stringify({
					token,
					_id,
				})}`,
				{ image: trimmedDataURL }
			)
			.then(({ data: deliveryCode }) => {
				this.setState({ deliveryCode });
			})
			.catch(() =>
				createSnackbar({
					message:
						'Nem sikerült elküldeni az aláírást, kérlek írj nekünk a chaten!',
					theme: 'error',
				})
			);
	};

	render() {
		const { deliveryCode } = this.state;
		return (
			<div className="row">
				<div
					id="recipient-signature"
					className="col-12 col-md-10 offset-md-1 pt-5 mt-5"
				>
					<h4 className="text-center mb-3">
						Érintésmentes aláírás - Vigyázzunk egymásra
					</h4>
					<h5 className="font-weight-normal text-secondary">
						Írd alá online a csomagodat bármikor a futárunk megérkezéséig saját
						eszközödön. Aláírás után egy titkos kódot kapsz amelyet megadva a
						futárnak érintés nélkül veheted át a csomagodat. A futár a kód
						megadásáig nem képes a csomagot leadni, tehát az aláírásod csak a
						titkos kód megadása után eredményez sikeres csomagátvételt.
					</h5>
					{deliveryCode ? (
						<p className="delivery-code mt-4">{deliveryCode}</p>
					) : (
						<>
							<SignatureCanvas
								ref={(ref) => {
									this.sigPad = ref;
								}}
							/>
							<button
								className="btn btn-light my-3 w-100"
								type="button"
								onClick={this.saveRecipientSignature}
							>
								Aláírás beküldése
							</button>
						</>
					)}
				</div>
			</div>
		);
	}
}

Signature.propTypes = {
	_id: PropTypes.string.isRequired,
	token: PropTypes.string.isRequired,
	createSnackbar: PropTypes.func.isRequired,
};

export default wrapComponent(Signature);
