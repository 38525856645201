import React from 'react';

function Icon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="224"
			height="226"
			viewBox="0 0 224 226"
		>
			<defs>
				<linearGradient id="a" x1="50%" x2="50%" y1="6.76%" y2="95.6%">
					<stop offset="0" stopColor="#00C6FF" />
					<stop offset="1" stopColor="#0068FF" />
				</linearGradient>
			</defs>
			<path
				fill="url(#a)"
				d="M41.255 185.52v40.2l37.589-21.37C89.322 207.37 100.46 209 112 209c61.86 0 112-46.79 112-104.5C224 46.786 173.86 0 112 0 50.144 0 0 46.786 0 104.5c0 32.68 16.078 61.86 41.255 81.02z"
			/>
			<path
				fill="#fff"
				d="M100.04 75.878L39.639 139.83l54.97-30.16 28.721 30.16 60.06-63.952-54.36 29.632-28.99-29.632z"
			/>
		</svg>
	);
}

export default Icon;
