/* eslint-disable react/jsx-curly-newline */
import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { hungarianDayFormat } from '../../../utils';
import { TextField } from '../../form/TextField';

const DeliveryForm = ({
	recipient_extra_notes_old,
	recipient_extra_notes,
	recipient_phone,
	editParcel,
}) => {
	const formatDate = (date) => {
		const momentDateValue = moment(date);

		return `${momentDateValue.format(
			'YYYY.MM.DD hh:mm:ss'
		)} ${hungarianDayFormat(momentDateValue.format('dddd'))}`;
	};

	return (
		<div className="row">
			<div className="col-12 col-md-10 offset-md-1 pt-5 mt-5">
				<h4 className="text-center pb-3">
					Adj meg további információkat a szállításodról, hogy biztosan megkapd
					csomagodat!
				</h4>
				<Formik
					initialValues={{
						recipientExtraNoteText: recipient_extra_notes.text,
						recipient_phone,
					}}
					onSubmit={(values, { setSubmitting }) =>
						editParcel(
							{
								...values,
								messageType: 'delivery',
							},
							setSubmitting
						)
					}
				>
					{({ isSubmitting }) => (
						<Form>
							<Field name="recipientExtraNoteText">
								{({ field, form }) => (
									<TextField
										label="Kulcsinformációk"
										multiline
										rows={3}
										field={field}
										form={form}
										note={
											recipient_extra_notes.text && [
												<strong>Utoljára módosítva:</strong>,
												' ',
												formatDate(recipient_extra_notes.date),
											]
										}
									/>
								)}
							</Field>
							<Field
								name="recipient_phone"
								label="Telefonszám"
								component={TextField}
							/>
							<button
								type="submit"
								className="btn btn-light my-3"
								disabled={isSubmitting}
							>
								Információk mentése
							</button>
						</Form>
					)}
				</Formik>
				{recipient_extra_notes_old?.length > 0 && (
					<div>
						<p
							style={{
								marginBottom: '0.5rem',
								marginTop: '1.5rem',
								fontWeight: 'bold',
							}}
						>
							Korábban megadott megjegyzések:
						</p>
						{recipient_extra_notes_old.map((note) => (
							<div style={{ marginBottom: '1rem' }}>
								<div>{formatDate(note.date)}</div>
								<div style={{ fontStyle: 'italic' }}>{note.text}</div>
							</div>
						))}
					</div>
				)}
			</div>
		</div>
	);
};

const NoteType = PropTypes.shape({
	text: PropTypes.string,
	date: PropTypes.date,
});

DeliveryForm.defaultProps = {
	recipient_extra_notes: {},
	recipient_extra_notes_old: [],
};

DeliveryForm.propTypes = {
	recipient_extra_notes_old: PropTypes.arrayOf(NoteType),
	recipient_extra_notes: NoteType,
	recipient_phone: PropTypes.string.isRequired,
	editParcel: PropTypes.func.isRequired,
};

export default DeliveryForm;
