import React from 'react';
import MessengerLogo from '../../common/MessengerLogo';
import './EnableMessenger.scss';

export const EnableMessenger = ({ messengerLink }) => (
	<div className="row" id="enable-messenger">
		<div className="col-12 col-md-10 offset-md-1 pt-5 mt-5 text-center">
			<h4 className="pb-3 text-info">Kérj Facebook Messenger értesítést!</h4>
			<h5 className="font-weight-normal text-secondary">
				Így még több és pontosabb információt tudunk küldeni a szállításról! A
				beszélgetés 1 napon belül törlődik. Átirányítás után mindenképp kattints
				az INDÍTÁS gombra!
			</h5>
			<a
				className="btn btn-outline-info px-4 py-2 mt-2"
				href={messengerLink}
				target="_blank"
				rel="noopener noreferrer"
			>
				<MessengerLogo />
				Értesítést kérek róla!
			</a>
		</div>
	</div>
);
